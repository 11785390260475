.allusercart_main_division {
    /* width: 80%; */
    margin: 30px 20px;
}


.allusercart_back_btn {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
    width: max-content;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.allusercart_username {
    margin-top: 10px;
    align-self: center !important;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--ecom-admin-font-color);
}