

.CategoryImage_main_division{
    /* width: 80%; */
    padding: 30px;
}

.CategoryImage_title {
    align-self: center!important;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--ecom-admin-font-color);
}
