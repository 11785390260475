.slider_main_division {
    /* width: 80%; */
}

.slider_main_sub_division {
    margin: 30px 20px;
}

.slider_title {
    /* align-self: center !important; */
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 22px;
    color: var(--theme-default);
}

.slider_addnew_button {
    width: 100px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0px;
    margin: 0px 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
}

.slider_table {
    width: 70%;
}

/* Table header Bold CSS Start */



.ant-table-thead>tr>th {
    width: 115px;
    font-weight: 700 !important;
    font-size: 17px;
    text-align: center !important;
    background: transparent;
    font-size: 14px;
}

/* Table header Bold CSS End */

/* Checkbox color change CSS Start */


/* .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--ecom-admin-font-color) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--ecom-admin-font-color)
} */

/* Checkbox color change CSS End */

/* .ant-table {
    border: 1px solid var(--ecom-admin-border);
    border-radius: 10px !important;
} */



.ant-pagination-item-active {
    font-weight: 500;
    color: var(--ecom-admin-secondary-color);
    background: var(--ecom-admin-font-color) !important;
    border-color: var(--ecom-admin-font-color) !important;
}

.ant-pagination-item {
    border-color: var(--ecom-admin-font-color) !important;
    margin: 0px 8px;
    min-width: 25px !important;
    height: 32px;
    margin-right: 0px !important;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: none !important;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
}

.slider_add_slider_title {
    color: var(--ecom-admin-font-color);
    font-weight: 500;
    display: flex;
    align-items: center;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    display: none !important;
}