@import url('http://fonts.cdnfonts.com/css/mukta');


.editproduct_main_division {
    margin: 30px 20px;
}

.editproduct_add_new_product_title {
    font-size: 25px;
    margin-bottom: 10px;
    color: var(--ecom-admin-font-color);
}

.editproduct_sub_main_division {
    display: flex;
}

.editproduct_add_new_product_division {
    width: 75%;
}

.editproductformControl {
    height: 0.2rem;
    width: 100%;
    border-radius: 5px;
    font-size: 15px;
    padding: 1.2rem 0.9rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-border-color-second);
    border: 1px solid var(--ecom-admin-border-color-second);
    margin-bottom: 10px;
    text-transform: capitalize;
}

.editproductformControl:hover {
    border-color: var(--ecom-admin-font-color);
}

.editproductformControl:focus-visible {
    border-color: transparent;
}

.editproduct_permalink_breadcrums {
    display: flex;
    margin: 0px 0px 40px 0px;
}

.editproduct_permalink {
    font-size: 15px;
    font-weight: 500;
    color: var(--ecom-admin-second-color-secondary);
}

.editproduct_link {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.profile-btn-edit {
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    font-size: 13px;
    border: none;
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    margin-left: 5px;
    color: var(--ecom-admin-font-color);
}

.editproduct_text_editor_App {
    margin: 0px 0px 20px 0px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
}

.editproduct_long_discription {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    color: var(--ecom-admin-font-color);
    width: 100%;
}

.editproduct_short_discription {
    font-size: 16px;
    font-weight: 500;
    color: var(--ecom-admin-font-color);
    width: 100%;
    text-transform: capitalize;
}


/* start product data section */


.editproduct_product_data_main_division {
    border: 1px solid var(--ecom-admin-font-color);
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white;
}

.editproduct_product_data {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-border-color);
}

.editproduct_product_data_title {
    font-weight: 500;
    width: 100%;
    color: var(--ecom-admin-font-color);
}

.editproduct_category_list_details {
    display: flex;
}

.editproduct_category_list {
    width: 20%;
    border-right: 1px solid var(--ecom-admin-light-border);
}

.editproduct_category {
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    color: var(--ecom-admin-second-secondary-color);
    border-bottom: 1px solid var(--ecom-admin-light-border);
    cursor: pointer;
}

/* .editproduct_category:hover{
    background-color: var(--ecom-admin-font-color);
    color: white;
} */
.active_editproduct_category {
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 1px solid var(--ecom-admin-light-border);
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.fa-solid {
    padding-right: 5px;
}


/* start general section */

.editproduct_general_field {
    width: 20%;
}

.editproduct_general_field1 {
    width: 50%;
}

.editproduct_general_tax_field {
    width: 30%;
}

.editproduct-form-label {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    height: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}


.editproduct-form-label1 {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    height: 35px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.editproductregularpriceformControl {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 1.0rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    margin-bottom: 10px;
}

.editproducttaxformControl {
    height: 30px;
    width: 295px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 0.6rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    margin-bottom: 10px;
}

.editproduct_schedule_clear {
    width: 30%;
    margin-left: 10px;
}

.editproduct_schedule {
    font-size: 15px;
    color: var(--ecom-admin-font-color);
    cursor: pointer;
}

.editproduct_clear {
    padding: 60px 0px 0px 0px;
    font-size: 15px;
    color: var(--ecom-admin-font-color);
    cursor: pointer;
}

.editproduct_main_general_field {
    width: 100%;
}

.editproduct_price_tag {
    border-bottom: 1px solid var(--ecom-admin-light-border);
    padding: 20px 0px 10px 10px;
    display: flex;
}

.editproduct_tax_tag {
    padding: 20px 0px 10px 10px;
    display: flex;
}

.editproduct_name_category {
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    border-radius: 5px;
    height: 30px;
    width: 350px;
    margin-bottom: 15px;
}

.editproduct-category-up-icon {
    padding-left: 250px;
    cursor: pointer;
}

.editproduct_category_dropdown {
    position: absolute;
    background-color: var(--ecom-admin-secondary-color);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    border-right: 1px solid var(--ecom-admin-light-border);
    cursor: pointer;
    width: 23%;
}

.editproduct_name_category_dropdown {
    color: var(--ecom-admin-second-secondary-color);
    padding: 0px 20px;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 15px;
}

/* end general section */


/* start inventory section */



.editproduct_inventory_text_field {
    display: flex;
    margin-bottom: 20px;
}

.editproduct_inventory_sku_title {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    width: 33%;
    /* margin-right: 105px; */
}

.editproduct_inventory_sku_title2 {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    /* margin-right: 57px; */
}

.editproductinventoryformControl {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 0.6rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    margin-right: 10px;
}

.editproduct-inventory-form-check {
    display: flex;
    margin-top: 20px;
}

.editproduct_inventory_manage_stock {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    margin-right: 43px;
    margin-top: 8px;
}

.editproduct-inventory-form-check-label {
    color: var(--ecom-admin-second-color-secondary);
    margin-left: 5px;
    font-size: 13px;
    font-weight: 300;
    height: 25px;
    margin-bottom: 10px;
}

.editproduct_inventory_stock_status_field {
    display: flex;
    margin-top: 20px;
    border-bottom: 1px solid var(--ecom-admin-light-border);
}

.editproduct_inventory_stock_status {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    /* margin-right: 60px; */
    width: 27%;
}

.editproduct_inventory_Sold_individually {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    margin-right: 80px;
}

/* end inventory section */

/* start shipping section */



.editproduct_shipping_text_field {
    display: flex;
    margin-bottom: 20px;
}

.editproduct_shipping_weight_title {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    /* margin-right: 70px; */
    width: 35%;
}

.editproduct_shipping_weight_title2 {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    /* margin-right: 47px; */
    width: 35%;
}

.editproduct_shipping_weight_title3 {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    /* margin-right: 57px; */
    width: 20%;
}

.editproductshippingformControl {
    height: 30px;
    width: 350px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 0.6rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    margin-right: 10px;
}

.editproduct_shipping_class_field {
    display: flex;
    margin-top: 20px;
}

.editproduct_shipping_class_field_title {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    margin-right: 135px;
}

.editproduct_shipping_dimension_text_field {
    display: flex;
    /* border-bottom: 1px solid #eee; */

}

.editproduct_shipping_dimension_title {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    /* margin-right: 42px; */
    width: 30%;
}

.editproductshippingdimensionformControl {
    height: 30px;
    width: 90px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 0.6rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    margin-right: 10px;
}


/* end shipping section */


/* start likend product section */

.editproduct_linked_product_text_field {
    display: flex;
    margin-bottom: 20px;
}

.editproduct_linked_product_upsell_title {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    margin-right: 90px;
}

.editproduct_shipping_cross_sells_title {
    font-size: 13px;
    color: var(--ecom-admin-second-color-secondary);
    display: flex;
    align-items: center;
    margin-right: 75px;
}

.editproductlinkedproductformControl {
    height: 40px;
    width: 350px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 0.6rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    margin-right: 10px;
}

/* end linked section  */

/* start publish section */

.editproduct_main_Social_division {
    width: 25%;
    margin-left: 20px;
}

.editproduct_main_publish_division {
    border: 1px solid var(--ecom-admin-border-color);
    margin-bottom: 20px;
}

.editproduct_publish_data {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-border-color);
}

.editproduct_publish_title {
    font-weight: 500;
    width: 100%;
    color: var(--ecom-admin-second-secondary-color);
}

.editproduct_publish_details_field {
    padding: 10px;
    display: flex;
}

.editproduct_publish_field_button {
    display: flex;
}

.editproduct_save_draft_division {
    width: 100%;
}

.editproduct-btn-save-draft {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 30px;
    /* width: 28%; */
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--ecom-admin-font-color);
    margin-bottom: 10px;
}

.editproduct-btn-preview {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 30px;
    /* width: 25%; */
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--ecom-admin-font-color);
    /* margin-left: 140px; */
}

.editproduct_edit_status {
    display: flex;
    margin-top: 10px;
}

.editproduct_status_icon {
    color: var(--ecom-admin-border-color-second);
    padding-right: 5px;
}

.editproduct_status {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.editproduct_draft {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.editproduct_edit {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: var(--ecom-admin-font-color);
}

.editproduct_edit_status1 {
    display: flex;
    margin-top: 10px;
    width: 100%;
}

.editproduct_status1 {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding-right: 5px;
    width: 40%;
}

.editproduct_draft1 {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-right: 5px;
    width: 60%;
}

.editproduct_edit1 {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: var(--ecom-admin-font-color);
}

.editproduct_galleryimg1 {
    width: 71%;
    height: 100px;
    object-fit: contain;
    margin: 10px 0;
}

.edit_multi_product_galleryimg {
    width: 30%;
    height: 100px;
    object-fit: contain;
}

.editproduct_galleryimg {
    margin-bottom: 20px;
}

.editproduct_galleryimg_product_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.editproduct_img_grid_division {
    border: 1px solid var(--ecom-admin-border-color-second);
    width: 50%;
    margin-top: 10px;
}

.editproduct_last_division_new_draft {
    padding: 10px;
    font-size: 13px;
    font-weight: 400;
}

.editproduct_new_draft {
    color: var(--ecom-admin-second-secondary-color);
    padding-bottom: 5px;
}

.editproduct_move_button {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
}

.editproduct_move_trash {
    color: var(--ecom-admin-font-color);
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.editproduct-btn-publish {
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
    border-radius: 5px;
    height: 30px;
    /* width: 25%; */
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--ecom-admin-font-color);
    /* margin-left: 140px; */
}

/* end publish section */


/* start product category section */

.editproduct_main_product_category {
    border: 1px solid var(--ecom-admin-border-color);
    margin-bottom: 20px;
}

.editproduct_category_main_division_list_title {
    padding: 10px;
}

.editproduct_category_list_title {
    display: flex;
}

.editproduct_all_category {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.editproduct_most_used {
    font-size: 14px;
    font-weight: 400;
    padding-left: 30px;
    cursor: pointer;
}

.editproduct_checkbox_button {
    margin-top: 15px;
}

.form-check-input {
    border: 1px solid var(--ecom-admin-border-color-second) !important;
    width: 18px;
    height: 18px;
    background-color: var(--ecom-admin-secondary-color) !important;
}

.form-check-input:focus {
    border-color: var(--ecom-admin-border-color-second);
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--ecom-admin-font-color) !important;
    border-color: var(--ecom-admin-font-color) !important;
}

.form-check-input:focus-visible {
    border: transparent;
}

.editproduct-form-check-label {
    color: var(--ecom-admin-second-secondary-color);
    margin-left: 5px;
    font-size: 14px;
    font-weight: 300;
}

.editproduct_margin_checkbox_label {
    margin-left: 20px;
}

.editproduct_second_margin_checkbox_label {
    margin-left: 40px;
}

/* end product category section */


/* start product tag section */

.editproduct_main_product_tag {
    border: 1px solid var(--ecom-admin-border-color);
    margin-bottom: 20px;
}

.editproduct_text_field {
    padding: 10px;
}

.editproduct_text_add_button {
    display: flex;
}

.editproducttagformControl {
    height: 30px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 1.0rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
}

.editproduct-btn-add {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 30px;
    width: 15%;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--ecom-admin-font-color);
    margin-left: 10px;
}

.editproduct_set_product_separate_tag {
    padding: 20px 10px 10px 0px;
    font-size: 13px;
    font-weight: 400;
    color: var(--ecom-admin-second-secondary-color);
}

.editproduct_set_product_tag {
    padding: 10px 10px 10px 0px;
    font-size: 13px;
    font-weight: 400;
    color: var(--ecom-admin-font-color);
}

/* end product tag section */


/* start product image section */


.editproduct_main_product_image {
    border: 1px solid var(--ecom-admin-border-color);
    background-color: white;
    margin-bottom: 20px;
}

.editproduct_set_product_image {
    padding: 20px 10px 20px 10px;
    font-size: 13px;
    font-weight: 400;
    color: var(--ecom-admin-font-color);
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 0px !important;
}

.editproduct_file_select_img {
    margin-top: 20px;
}

.editproduct_image_photo_division {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}


/* end product image section */


/* start product gallery section */


.editproduct_main_product_gallery {
    border: 1px solid var(--ecom-admin-border-color);
    height: max-content;
    padding-bottom: 20px;
    background-color: white;
}

.editproduct_set_product_gallery {
    padding: 20px 10px 20px 10px;
    font-size: 13px;
    font-weight: 400;
    color: var(--ecom-admin-font-color);
}




white_check_mark eyes raised_hands .editproduct_detail_save_order_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-color);
    width: 300px;
}

.editproduct_detail_save_order_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.editproduct_detail_save_order_dropdown:hover {
    background-color: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-font-color);
}

.bgColorRed {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.editproduct_add_icon {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: var(--ecom-admin-font-color);
}

.editproductcategoryformControl {
    height: 35px;
    width: 200px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 1.0rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
    /* margin-left: 5px;  */
    text-transform: capitalize;
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-second-secondary-border-color);
}

.editproduct_category_add_btn {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 35px;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--ecom-admin-font-color);
    margin-left: 10px;
}

.addprduct_main_category_field {
    padding: 10px;
    width: 80%;
}

.editproduct_main_category_field {
    padding: 10px;
    width: 80%;
}

.editproduct_main_inventory_field {
    padding: 10px;
}

.editproduct_category_text_field {
    display: flex;
    margin-bottom: 20px;
}

.editproduct_main_shipping_field {
    padding: 10px;
}

.editproduct_main_linked_product_field {
    padding: 10px;
}

/* end product gallery section */





@media only screen and (max-width:768px) {

    .editproduct_main_general_field {
        width: 80%;
    }

    .editproductregularpriceformControl {
        width: 200px;
    }

    .editproduct_main_inventory_field {
        width: 80%;
    }

    .editproduct_main_linked_product_field {
        width: 80%;
    }

    .editproduct-btn-publish {
        margin-top: 5px;
    }

    .editproduct_move_button {
        display: block;
    }

    .editproduct_input_file {
        width: 90%;
    }
}

.ant-table{
    text-transform: capitalize !important;
}