

.alluser_main_division{
    /* width: 80%; */
    
}
.alluser_main_sub_division{
    margin: 30px 20px;
}
.allusers_title {
    align-self: center!important;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 22px;
    color: var(--ecom-admin-font-color);
}

.allusers_addnew_button {
    width: 100px;
    font-size: 16px;
    font-weight: 500;
    padding: 0px;
    margin: 0px 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--ecom-admin-font-color)!important;
    color: var(--ecom-admin-secondary-color)!important;
    height: 40px;
}

.bgColorRed {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allusers_addnew_button:focus {
    box-shadow: none;
}

.allusers_addnew_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.allusers_search_feild {
    width: 250px;
    border: 1px solid var(--ecom-admin-border-color);
    height: 40px;
}

.allusers_search_feild:focus {
    border: 1px solid var(--ecom-admin-font-color) !important;
    box-shadow: none;
    color: var(--ecom-admin-second-secondary-color) !important;
    background-color: var(--ecom-admin-secondary-color)!important;
}

.allusers_search_button {
    font-size: 16px;
    font-weight: 500;
    background-color: var(--ecom-admin-font-color)!important;
    color: var(--ecom-admin-secondary-color)!important;
    height: 40px;
}

.allusers_search_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.allusers_search_button:focus {
    box-shadow: none;
}

.allusers_bulkaction_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.allusers_bulkaction_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.allusers_bulkaction_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.alluser_bulkaction_apply_button {
    margin: 0px 5px;
    padding: 0px 25px;
}

.alluser_bulkaction_apply_button:focus {
    box-shadow: none;
}
.alluser_bulkaction_change_button{
    margin: 0px 5px;
    padding: 0px 25px;
}
.alluser_bulkaction_change_button:focus {
    box-shadow: none;
}
.alluser_select_category {
    width: 200px;
    border: 1px solid var(--ecom-admin-border);
}

.alluser_select_category:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none;
}

.alluser_filterbyproduct_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.alluser_filterbyproduct_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.alluser_filterbyproduct_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.alluser_filterbystockstatus_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.alluser_filterbystockstatus_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.alluser_filterbystockstatus_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.alluser_aacs_division {
    display: flex;
}
.active_alluser_aacs{
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
    width: max-content;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
}
.active_alluser_aacs span{
    padding-left: 5px;
}
.alluser_aacs {
    color: var(--ecom-admin-font-color);
    font-size: 16px;
    align-self: center;
    margin-left:5px;
    cursor: pointer;
}
.alluser_aacs span {
    color: var(--ecom-admin-border);
    margin: 0px 5px;
}
