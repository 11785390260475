:root {
    --ecom-admin-font-color: #483a15;

    --ecom-admin-border-color: #c3c4c7;
    --ecom-admin-light-border: #eee;

    --ecom-admin-secondary-color: #fff;

    --ecom-admin-second-secondary-color: #000;

    --ecom-admin-border-color-second: rgb(118, 117, 117);
    --ecom-admin-second-color-secondary: #555;
    --ecom-admin-second-secondary-border-color: #8c8f94;
    --ecom-admin-background-color: #717171;
    --ecom-admin-second-color: #999;
    --ecom-admin-border: #ced4da;


    --back-color: #FCFDF8;
    --bean-color: #b86514;
}