@import url("http://fonts.cdnfonts.com/css/mukta");

.addproduct_main_division {
  margin: 30px 20px;
}

.addproduct_add_new_product_title {
  font-size: 25px;
  margin-bottom: 10px;
  color: var(--ecom-admin-font-color);
}

.addproduct_sub_main_division {
  display: flex;
}

.addproduct_add_new_product_division {
  width: 70%;
}

.addproductformControl {
  height: 0.2rem;
  width: 100%;
  border-radius: 5px;
  font-size: 15px;
  padding: 1.2rem 0.9rem;
  background: var(--back-color);
  color: var(--ecom-admin-border-color-second);
  border: 1px solid var(--ecom-admin-border-color-second);
  margin-bottom: 10px;
  margin-right: 10px;
  text-transform: capitalize;
}

.addproductformControl:hover {
  border-color: var(--ecom-admin-font-color);
}

.addproductformControl:focus-visible {
  border-color: transparent;
}

.addproduct_permalink_breadcrums {
  display: flex;
  margin: 0px 0px 40px 0px;
}

.addproduct_permalink {
  font-size: 15px;
  font-weight: 500;
  color: var(--ecom-admin-second-color-secondary);
}

.addproduct_link {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.profile-btn-edit {
  background-color: var(--ecom-admin-secondary-color);
  border-radius: 5px;
  font-size: 13px;
  border: none;
  border: 1px solid var(--ecom-admin-font-color);
  cursor: pointer;
  margin-left: 5px;
  color: var(--ecom-admin-font-color);
}

.addproduct_text_editor_App {
  /* background: var(--ecom-admin-secondary-color); */
  margin: 0px 0px 20px 0px;
  padding: 10px;
  /* border-radius: 10px; */
  /* border: 1px solid var(--ecom-admin-font-color); */
}

.addproduct_long_discription {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  color: var(--ecom-admin-font-color);
}

.addproduct_short_discription {
  font-size: 16px;
  font-weight: 500;
  color: var(--ecom-admin-font-color);
  width: 100%;
  text-transform: capitalize;
}

.tax_main_div{
  width: 80%;
}

/* start product data section */

.addproduct_product_data_main_division {
  border: 1px solid var(--ecom-admin-font-color);
  background: var(--back-color);
  border-radius: 10px;
  margin-bottom: 20px;
}

.addproduct_product_data {
  display: flex;
  padding: 10px;
}

.addproduct_product_data_title {
  font-weight: 500;
  width: 100%;
  color: var(--ecom-admin-font-color);
}

.addproduct_category_list_details {
  display: flex;
}

.addproduct_category_list {
  width: 20%;
  border-right: 1px solid var(--ecom-admin-light-border);
}

.addproduct_category {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  color: var(--ecom-admin-second-secondary-color);
  border-bottom: 1px solid var(--ecom-admin-light-border);
  cursor: pointer;
}

/* .addproduct_category:hover{
    background-color: var(--ecom-admin-font-color);
    color: white;
} */
.active_addproduct_category {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid var(--ecom-admin-light-border);
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.fa-solid {
  padding-right: 5px;
}

/* start general section */

.addproduct_general_field {
  width: 20%;
}

.addproduct_general_field1 {
  width: 50%;
}

.addproduct_general_tax_field {
  width: 30%;
}

.addproduct-form-label {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.addproduct-form-label1 {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  height: 35px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.addproductregularpriceformControl {
  height: 30px;
  width: 350px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 1rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  margin-bottom: 10px;
}

.addproducttaxformControl {
  height: 30px;
  width: 350px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  margin-bottom: 10px;
}

.addproduct_schedule_clear {
  width: 30%;
  margin-left: 10px;
}

.addproduct_schedule {
  font-size: 15px;
  color: var(--ecom-admin-font-color);
  cursor: pointer;
}

.addproduct_clear {
  padding: 60px 0px 0px 0px;
  font-size: 15px;
  color: var(--ecom-admin-font-color);
  cursor: pointer;
}

.addproduct_main_general_field {
  width: 100%;
}

.addproduct_price_tag {
  border-bottom: 1px solid var(--ecom-admin-light-border);
  padding: 20px 0px 10px 10px;
  display: flex;
}

.addproduct_tax_tag {
  padding: 20px 0px 10px 10px;
  display: flex;
}

.addproduct_name_category {
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  border-radius: 5px;
  height: 30px;
  width: 350px;
  margin-bottom: 15px;
}

.addproduct-category-up-icon {
  padding-left: 250px;
  cursor: pointer;
}

.addproduct_category_dropdown {
  position: absolute;
  background-color: var(--ecom-admin-secondary-color);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
  border-right: 1px solid var(--ecom-admin-light-border);
  cursor: pointer;
  width: 23%;
}

.addproduct_name_category_dropdown {
  color: var(--ecom-admin-second-secondary-color);
  padding: 0px 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 15px;
}

/* end general section */

/* start inventory section */

.addprduct_main_inventory_field {
  padding: 10px;
  width: 100%;
}

.addproduct_inventory_text_field {
  display: flex;
  margin-bottom: 20px;
}

.addproduct_inventory_sku_title {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 105px;
}

.addproduct_inventory_sku_title2 {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 57px;
}

.addproductinventoryformControl {
  height: 30px;
  width: 350px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  margin-right: 10px;
}

.addproduct-inventory-form-check {
  display: flex;
  margin-top: 20px;
}

.addproduct_inventory_manage_stock {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 43px;
  margin-top: 8px;
}

.addproduct-inventory-form-check-label {
  color: var(--ecom-admin-second-color-secondary);
  margin-left: 5px;
  font-size: 13px;
  font-weight: 300;
  height: 25px;
  margin-bottom: 10px;
}

.addproduct_inventory_stock_status_field {
  display: flex;
  margin-top: 20px;
  border-bottom: 1px solid var(--ecom-admin-light-border);
}

.addproduct_inventory_stock_status {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 60px;
}

.addproduct_inventory_Sold_individually {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 80px;
}

/* end inventory section */

/* start shipping section */

.addprduct_main_shipping_field {
  padding: 10px;
  width: 100%;
}

.addproduct_shipping_text_field {
  display: flex;
  margin-bottom: 20px;
}

.addproduct_shipping_weight_title {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  /* margin-right: 70px; */
  width: 20%;
}

.addproduct_shipping_weight_title2 {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  /* margin-right: 47px; */
  width: 20%;
}

.addproduct_shipping_weight_title3 {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  /* margin-right: 57px; */
  width: 20%;
}

.addproductshippingformControl {
  height: 30px;
  width: 350px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  margin-right: 10px;
}

.addproduct_shipping_class_field {
  display: flex;
  margin-top: 20px;
}

.addproduct_shipping_class_field_title {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 135px;
}

.addproduct_shipping_dimension_text_field {
  display: flex;
  /* border-bottom: 1px solid #eee; */
}

.addproduct_shipping_dimension_title {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  width: 20%;
  /* margin-right: 42px; */
}

.addproductshippingdimensionformControl {
  height: 30px;
  width: 110px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  margin-right: 10px;
}

/* end shipping section */

/* start likend product section */

.addprduct_main_linked_product_field {
  padding: 10px;
  width: 80%;
}

.addproduct_linked_product_text_field {
  display: flex;
  margin-bottom: 20px;
}

.addproduct_linked_product_upsell_title {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 90px;
}

.addproduct_shipping_cross_sells_title {
  font-size: 13px;
  color: var(--ecom-admin-second-color-secondary);
  display: flex;
  align-items: center;
  margin-right: 75px;
}

.addproductlinkedproductformControl {
  height: 40px;
  width: 350px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  margin-right: 10px;
}

/* end linked section  */

/* start publish section */

.addproduct_main_Social_division {
  width: 30%;
  margin-left: 20px;
}

.addproduct_main_publish_division {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.addproduct_publish_data {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--ecom-admin-border-color);
}

.addproduct_publish_title {
  font-weight: 500;
  width: 100%;
  color: var(--ecom-admin-second-secondary-color);
}

.addproduct_publish_details_field {
  padding: 10px;
}

.addproduct_btn_save_draft {
  color: var(--ecom-admin-font-color);
  background-color: var(--ecom-admin-secondary-color);
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--ecom-admin-font-color);
  padding: 8px;
}

.addproduct-btn-preview {
  color: var(--ecom-admin-font-color);
  background-color: var(--ecom-admin-secondary-color);
  border-radius: 5px;
  height: 30px;
  /* width: 25%; */
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--ecom-admin-font-color);
  /* margin-left: 140px; */
}

.addproduct_edit_status {
  display: flex;
  margin-top: 10px;
}

.addproduct_status_icon {
  color: var(--ecom-admin-border-color-second);
  padding-right: 5px;
}

.addproduct_status {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.addproduct_draft {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.addproduct_edit {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--ecom-admin-font-color);
}

.addproduct_edit_status1 {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.addproduct_status1 {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-right: 5px;
  width: 40%;
}

.addproduct_draft1 {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-right: 5px;
  width: 60%;
}

.addproduct_edit1 {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--ecom-admin-font-color);
}

.addproduct_galleryimg_product_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.addproduct_img_grid_division {
  /* border: 1px solid var(--ecom-admin-border-color-second); */
  width: 50%;
  margin-top: 10px;
}

.addproduct_last_division_new_draft {
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
}

.addproduct_new_draft {
  color: var(--ecom-admin-second-secondary-color);
  padding-bottom: 5px;
}

.addproduct_move_trash {
  color: var(--ecom-admin-font-color);
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.addproduct_btn_publish {
  color: var(--ecom-admin-secondary-color);
  background-color: var(--ecom-admin-font-color);
  border-radius: 5px;
  padding: 8px;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--ecom-admin-font-color);
}

/* end publish section */

/* start product category section */

.addproduct_main_product_category {
  border: 1px solid var(--ecom-admin-border-color);
  margin-bottom: 20px;
}

.addproduct_category_main_division_list_title {
  padding: 10px;
}

.addproduct_category_list_title {
  display: flex;
}

.addproduct_all_category {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.addproduct_most_used {
  font-size: 14px;
  font-weight: 400;
  padding-left: 30px;
  cursor: pointer;
}

.addproduct_checkbox_button {
  margin-top: 15px;
}

.form-check-input {
  border: 1px solid var(--ecom-admin-border-color-second) !important;
  width: 18px;
  height: 18px;
  background-color: var(--ecom-admin-secondary-color) !important;
}

.form-check-input:focus {
  border-color: var(--ecom-admin-border-color-second);
  box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--ecom-admin-font-color) !important;
  border-color: var(--ecom-admin-font-color) !important;
}

.form-check-input:focus-visible {
  border: transparent;
}

.addproduct-form-check-label {
  color: var(--ecom-admin-second-secondary-color);
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
}

.addproduct_margin_checkbox_label {
  margin-left: 20px;
}

.addproduct_second_margin_checkbox_label {
  margin-left: 40px;
}

/* end product category section */

/* start product tag section */

.addproduct_main_product_tag {
  border: 1px solid var(--ecom-admin-border-color);
  margin-bottom: 20px;
}

.addproduct_text_field {
  padding: 10px;
}

.addproduct_text_add_button {
  display: flex;
}

.addproducttagformControl {
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 1rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
}

.addproduct-btn-add {
  color: var(--ecom-admin-font-color);
  background-color: var(--ecom-admin-secondary-color);
  border-radius: 5px;
  height: 30px;
  width: 15%;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--ecom-admin-font-color);
  margin-left: 10px;
}

.addproduct_set_product_separate_tag {
  padding: 20px 10px 10px 0px;
  font-size: 13px;
  font-weight: 400;
  color: var(--ecom-admin-second-secondary-color);
}

.addproduct_set_product_tag {
  padding: 10px 10px 10px 0px;
  font-size: 13px;
  font-weight: 400;
  color: var(--ecom-admin-font-color);
}

/* end product tag section */

/* start product image section */

.addproduct_main_product_image {
  border: 1px solid var(--ecom-admin-font-color);
  margin-bottom: 20px;
  border-radius: 10px;
  background: var(--back-color);
}

.addproduct_set_product_image {
  padding: 20px 10px 20px 10px;
  font-size: 13px;
  font-weight: 400;
  color: var(--ecom-admin-font-color);
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 0px !important;
}

.addproduct_file_select_img {
  margin-top: 20px;
}

.addproduct_image_photo_division {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* end product image section */

/* start product gallery section */

.addproduct_main_product_gallery {
  border: 1px solid var(--ecom-admin-font-color);
  border-radius: 10px;
  height: max-content;
  padding-bottom: 20px;
  background: var(--back-color);
}

.addproduct_set_product_gallery {
  padding: 20px 10px 20px 10px;
  font-size: 13px;
  font-weight: 400;
  color: var(--ecom-admin-font-color);
}

white_check_mark eyes raised_hands .addproduct_detail_save_order_dropdown {
  background-color: var(--ecom-admin-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-color);
  width: 300px;
}

.addproduct_detail_save_order_dropdown:focus {
  border: 1px solid var(--ecom-admin-font-color);
  box-shadow: none;
}

.addproduct_detail_save_order_dropdown:hover {
  background-color: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-font-color);
}

.bgColorRed {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.addproduct_add_icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: var(--ecom-admin-font-color);
}

.addproductcategoryformControl {
  height: 35px;
  width: 200px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 1rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-secondary-border-color);
  /* margin-left: 5px;  */
  text-transform: capitalize !important;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--ecom-admin-second-secondary-border-color);
}

.addproduct_category_add_btn {
  color: var(--ecom-admin-font-color);
  background-color: var(--ecom-admin-secondary-color);
  border-radius: 5px;
  height: 35px;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--ecom-admin-font-color);
  margin-left: 10px;
}

.addprduct_main_category_field {
  padding: 10px;
  width: 80%;
}

.addproduct_category_text_field {
  display: flex;
  margin-bottom: 20px;
}

/* end product gallery section */

@media only screen and (max-width: 768px) {
  .addproduct_main_Social_division {
    margin-left: 10px;
  }

  .addproduct_main_general_field {
    width: 80%;
  }

  .addprduct_main_inventory_field {
    width: 80%;
  }

  .addprduct_main_shipping_field {
    width: 80%;
  }

  .addprduct_main_linked_product_field {
    width: 80%;
  }

  .addproductregularpriceformControl {
    width: auto;
  }

  .addproductshippingdimensionformControl {
    width: 100px;
  }

  .addproduct_inputtag {
    width: 90%;
  }

  .addproductinventoryformControl {
    width: 285px;
  }

  .addproductlinkedproductformControl {
    width: 282px;
  }
}

@media only screen and (max-width: 1024px) {
  .addproduct_schedule {
    margin-left: 60px;
  }
}

.popup_input:focus {
  border-color: var(--ecom-admin-font-color);
  box-shadow: none;
}
