@import url('http://fonts.cdnfonts.com/css/mukta');


.specification_main_division {
    /* margin-left: 160px; */
    padding: 50px 20px;
}

.specification_title {
    font-size: 25px;
    margin-bottom: 50px;
    color: var(--ecom-admin-font-color);
}

.specification_sub_main_division {
    display: flex;
}

.specification_first_main_division {
    width: 40%;
    margin-right: 30px;
}

.specification_label_text {
    display: flex;
}

.specification_button {
    margin-top: 20px;

}

.specification-detailed-add-button {
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 3px;
    font-size: 13px;
    width: 50px;
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    height: 30px;
    color: var(--ecom-admin-font-color);
}

.specification-submit-button {
    background-color: var(--ecom-admin-font-color);
    border-radius: 3px;
    font-size: 13px;
    /* width: 60px; */
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    height: 30px;
    color: var(--ecom-admin-secondary-color);
    margin-left: 20px;
}

.specification_Key_Specification_title {
    font-size: 15px;
    font-weight: 500;
    color: var(--ecom-admin-second-secondary-color);
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.specificationformControl {
    height: 30px;
    width: 250px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 0.6rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
}

.specification-add-button {
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 3px;
    font-size: 13px;
    width: 50px;
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    height: 30px;
    color: var(--ecom-admin-font-color);
    margin-left: 10px;
}

.specification_sub_title {
    font-size: 20px;
    margin-top: 40px;
    font-weight: 500;
    color: var(--ecom-admin-font-color);
}

.specification_text_title {
    font-size: 15px;
    font-weight: 500;
    color: var(--ecom-admin-second-secondary-color);
    margin-top: 15px;
}

.specificationdropdownformControl {
    width: 49%;
    height: 30px;
    border-radius: 5px;
    font-size: 15px;
    padding: 0rem 0.1rem;
    background: var(--ecom-admin-secondary-color);
    color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-second-secondary-border-color);
}


.specification_second_main_division {
    width: 50%;
}

.specification_table_division {
    margin-top: 10px;
    border: 1px solid var(--ecom-admin-border-color);
    border-radius: 10px;
}

.specification_table_heading {
    color: var(--ecom-admin-font-color);
}

.specification_table_value {
    font-size: 13px;
    font-weight: 500;
}

.specification_table_icon_edit {
    cursor: pointer;
}

.specification_table_icon {
    color: var(--ecom-admin-font-color);
    cursor: pointer;
}

.specification-previous-button {
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 3px;
    font-size: 13px;
    width: 70px;
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    height: 30px;
    color: var(--ecom-admin-font-color);
    margin-top: 30px;
    margin-right: 20px;
}

.specification-next-button {
    background-color: var(--ecom-admin-font-color);
    border-radius: 3px;
    font-size: 13px;
    width: 70px;
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    height: 30px;
    color: var(--ecom-admin-secondary-color);
}

.specification_table_main_division {
    width: 60%;
}



/* Checkbox color change CSS Start */

/* .ant-table-thead>tr>th {
    font-weight: 700 !important;
    font-size: 17px;
    text-align: center !important;
} */

/* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--ecom-admin-font-color) !important;
    border-color: var(--ecom-admin-font-color) !important;
} */

/* .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--ecom-admin-font-color) !important;
} */

/* .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--ecom-admin-font-color) !important;
} */

/* Checkbox color change CSS End */

/* .ant-pagination-item-active {
    font-weight: 500;
    color: var(--ecom-admin-secondary-color) !important;
    border-color: transparent !important;
} */

/* .ant-pagination-item {
    border-color: transparent !important;
} */

.ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--ecom-admin-second-secondary-color) !important;
    line-height: 1.5715;
    list-style: none;
    position: relative;
    font-size: 14px;
    background: white !important;
    border-radius: 10px !important;
}

/* .ant-table-thead>tr>th {
    position: relative;
    color: var(--ecom-admin-second-secondary-color) !important;
    font-weight: 500 !important;
    text-align: left;
    background: var(--ecom-admin-secondary-color) !important;
    border-radius: 10px !important;
    border-bottom: none !important;
    transition: background 0.3s ease;
} */



.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
}

.ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-left: 0px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: transparent !important;
    border-radius: 5px !important;
    outline: 0;
    cursor: pointer;
}

.ant-pagination-item a {
    display: block;
    padding: 0px 10px !important;
    transition: none;
}

/* .ant-pagination-prev button,
.ant-pagination-next button {
    color: var(--ecom-admin-font-color) !important;
} */

/* a:active {
    color: var(--ecom-admin-font-color) !important;
} */

/* .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: transparent !important;
} */

/* .ant-table table {
    width: 100%;
    text-align: center !important;
    border-radius: 2px 2px 0 0 !important;
    border-collapse: separate;
    border-spacing: 0;
} */

.style {
    /* color: var(--ecom-admin-secondary-color) !important; */
    /* font-size: 16px; */
}

table tr th.ant-table-selection-column {
    /* padding-right: 8px; */
    /* padding-left: 8px; */
    display: none !important;
}

td.ant-table-selection-column {
    /* padding-right: 8px; */
    /* padding-left: 8px; */
    /* text-align: center; */
    display: none;
}

/* .ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    position: relative;
    padding: 17px 17px !important;
    overflow-wrap: break-word;
} */

/* .ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    margin-right: 0px !important;
} */

/* .ant-table-tbody>tr.ant-table-row-selected>td {
    background: none !important;
} */

/* .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
} */


@media only screen and (max-width:768px) {

    .specification_sub_main_division {
        display: block;
    }

    .specification_first_main_division {
        width: 100%;
    }

    .specification_table_main_division {
        width: 100%;
    }
}



/* .ant-table-tbody>tr>td {
    border-bottom: none !important;
    transition: background 0.3s;
} */




