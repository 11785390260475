.allproduct_main_division {
    /* width: 80%; */
}

.allproduct_main_sub_division {
    margin: 30px 20px;
}

.allproducts_title {
    align-self: center !important;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 22px;
    color: var(--ecom-admin-font-color) !important;
}

.allproducts_addnew_button {
    width: 100px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0px;
    margin: 0px 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
}

.bgColorRed {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_addnew_button:focus {
    box-shadow: none;
}

.allproducts_addnew_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.allproducts_search_feild {
    width: 250px;
    border: 1px solid #b7b7b7;
    padding: 0px 10px;
    height: 40px;
    border-radius: 5px;
}

.allproducts_search_feild:focus {
    border: 1px solid var(--ecom-admin-second-color);
    box-shadow: none;
}

.allproducts_search_button {
    font-size: 16px;
    font-weight: 500;
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
    padding: 0px 10px;
    height: 40px;
}

.allproducts_search_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.allproducts_search_button:focus {
    box-shadow: none;
}

.allproducts_bulkaction_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.allproducts_bulkaction_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.allproducts_bulkaction_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_bulkaction_apply_button {
    margin: 0px 5px;
    padding: 0px 25px;
}

.allproducts_bulkaction_apply_button:focus {
    box-shadow: none;
}

.allproducts_select_category {
    width: 200px;
    border: 1px solid var(--ecom-admin-border);
}

.allproducts_select_category:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none;
}

.allproducts_filterbyproduct_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    margin: 0px 5px;
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.allproducts_filterbyproduct_dropdown:focus {
    border: 1px solid var(--ecom-admin-border);
    box-shadow: none
}

.allproducts_filterbyproduct_dropdown_menu:hover {
    background-color: var(--ecom-admin-border);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_filterbystockstatus_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.allproducts_filterbystockstatus_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.allproducts_filterbystockstatus_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_apds_division {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}

.active_allproducts_apds {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
    width: max-content;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0px;

}

.active_allproducts_apds2 {
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0px;
}

.active_allproducts_apds span {
    padding-left: 5px;
}

.allproducts_apds {
    color: var(--ecom-admin-font-color);
    font-size: 16px;
    align-self: center;
    margin-left: 5px;
}

.allproducts_apds span {
    color: var(--ecom-admin-border);
    margin: 0px 5px;
}

.allproduct_table_number {
    width: 100%;

}

.all_products_tables .ant-table {
    -overflow-style: none !important;
    overflow-x: scroll !important;
}

.all_products_tables .ant-table::-webkit-scrollbar {
    display: none;
}



/* Checkbox color change CSS Start */

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--ecom-admin-font-color) !important;
    border-color: var(--ecom-admin-font-color) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--ecom-admin-font-color) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--ecom-admin-font-color) !important;
}

/* Checkbox color change CSS End */
.ant-pagination-item-active {
    font-weight: 500;
    color: var(--ecom-admin-secondary-color);
    background: var(--ecom-admin-font-color) !important;
    border-color: var(--ecom-admin-font-color) !important;
}

.ant-pagination-item {
    border-color: var(--ecom-admin-font-color) !important;
}

.ant-pagination-item a {
    display: block;
    padding: 0 0px !important;
    transition: none;
}





.custom-file-button {
    width: 35%;
}

.custom-file-button input[type=file] {
    margin-left: -2px !important;
    color: var(--ecom-admin-font-color);
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
    display: none;
}

.custom-file-button input[type=file]::file-selector-button {
    display: none;
}

.custom-file-button>label {
    background-color: var(--ecom-admin-font-color);
    cursor: pointer;
    color: white;

}



.addproduct_inputtag:focus {
    background-color: white !important;

}

.addproduct_inputtag {
    border: none !important;
}


.ee {
    display: flex;
    justify-content: end !important;
}