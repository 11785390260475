.sidebarheader {
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}

.sidebar {
  display: block;
  width: 17% !important;
  /* background: #7c6a39e0; */
  background: #8f7d4f;
  height: 100vh !important;
  position: fixed;
}

.sidebar2 {
  padding: 20px 10px;
}

/* accordian_css_start */


.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: transparent !important;
}

.sidebar_list_name {
  color: var(--ecom-admin-secondary-color) !important;
  margin: 0px 15px;
}

.sidebar_list_name:hover {
  color: var(--ecom-admin-font-color) !important;
  background: white !important;
  border-radius: 5rem !important;
}

.sidebar_list_name:hover p i {
  transform: scale(1.4) !important;
  transition: 0.3s;
}

.sidebar_list_name_active {
  color: var(--back-color) !important;
  border-radius: 5rem !important;
  border-right: 4px solid transparent !important;
  border-left: 4px solid transparent !important;
  border-left-color: var(--ecom-admin-font-color) !important;
  border-right-color: var(--ecom-admin-font-color) !important;
  background-color: #483a1590 !important;
}

.sidebar_list_name_active:hover {
  color: var(--back-color) !important;
}

.sidebar_list_name_active:focus-within {
  height: 48px !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: var(--ecom-admin-font-color) !important;
}

.sidebar_subname_lists {
  list-style: none;
  color: var(--ecom-admin-secondary-color);
  padding: 7px 0px;
  font-size: 14px;
  letter-spacing: 0.06em;
  padding-left: 15px;
  cursor: pointer;
  font-family: Poppins, sans-serif !important;
}

.sidebar_subname_lists:hover {
  color: var(--ecom-admin-font-color);
  border-radius: 5rem;
  background-color: white;
}

.sidebar_subname_lists span {
  font-size: 15px;
  width: 10px !important;
  padding-right: 3px;
}

.sidebar_subname_lists_active {
  list-style: none;
  color: var(--ecom-admin-secondary-color);
  padding: 7px 0px;
  font-size: 14px;
  letter-spacing: 0.06em;
  padding-left: 15px;
  cursor: pointer;
  font-family: Poppins, sans-serif !important;
  border-radius: 5rem !important;
  border-right: 4px solid transparent !important;
  border-left: 4px solid transparent !important;
  border-left-color: var(--ecom-admin-font-color) !important;
  border-right-color: var(--ecom-admin-font-color) !important;
  background-color: #483a1590 !important;
}

.sidebar_list_name_division {
  margin: 0px !important;
  box-shadow: none !important;
}

.sidebar_two {
  top: 15px;
  width: 0%;
  position: fixed;
  z-index: 999;
}

.bar_icon {
  display: inline-block;
  width: 30px;
  margin-left: 10px;
}

.bar_span_one {
  background-color: #483a15;
  border-radius: 2px;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 0;
  width: 30px;
  margin-bottom: 6px;
}

.bar_span_two {
  background-color: #483a15;
  border-radius: 2px;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 6px;
  width: 15px;
}

.bar_span_three {
  background-color: #483a15;
  border-radius: 2px;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 0;
  width: 30px;
}

@-webkit-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show-box {
  -webkit-animation: SHOW-BOX 1s ease;
  -moz-animation: SHOW-BOX 1s ease;
  -o-animation: SHOW-BOX 1s ease;
  animation: SHOW-BOX 1s ease;
  padding-left: 0px !important;
  transition: 0.5s ease-in;
}

li {
  list-style: none;
}

.new_sidebar_list_name_active {
  color: var(--back-color);
  border-left-color: var(--ecom-admin-font-color);
  border-right-color: var(--ecom-admin-font-color);
  background-color: #483a1590;
  cursor: pointer;
  font-family: 'poppins';
}

.new_sidebar_list_name_active:hover {
  color: var(--back-color);
}

.new_sidebar_list_name {
  color: var(--ecom-admin-secondary-color);
  padding: 10px 5px;
  cursor: pointer;
  font-family: 'poppins';
}

.new_sidebar_list_name:hover {
  color: white;
}

.s {
  padding: 10px;
}

.drawer_class {
  background-color: #8f7d4f !important;
}

.css-1nt25v5-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #8f7d4f !important;
}

@media only screen and (min-width: 1025px) {
  .sidebar_two {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }

  .adminpanel_outletmain_div {
    margin-left: 0%;
  }

  .Adminheader_main_div {
    width: 100% !important;
  }
}















/* sidebar three start */


.sidebar_three {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #8f7d4f;
  transition: all 0.2s ease-in-out 0s;
  padding: 10px;
  overflow: hidden;
  z-index: 100;
}

.active {
  background-color: #483a15;
  position: relative;
  margin-bottom: 10px;
}

.non_active {
  margin-bottom: 10px;
}

.non_active:hover {
  background-color: #483a15;
}

.link {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: #f1f1f1;
  padding: 15px 15px;
  list-style-type: none !important;
  margin-bottom: 10px;
  cursor: pointer;
}

.link:hover {
  color: #f1f1f1;
}

.span_li {
  display: inline-block;
  margin-left: 30px;
  white-space: nowrap;
}

.menu_icon {
  margin-left: 4px;
}



.main_sidebar {
  height: 100vh;
  background-color: white;
  width: 270px;
  position: fixed;
  overflow: scroll;
}

.sidebar_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: white;
  border-bottom: 1px solid #eaeaea;
  position: fixed;
  top: 0;
  padding: 10px 5px;
}







.dash {
  color: #8484e8;
  font-family: sans-serif;
}

.dashboard_div {
  display: flex;
}

.dashboard_main_div {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 20px;


}

.dashboard_main_div:hover {
  background-color: #F0F4FB;
  color: rgb(81, 81, 172);
  border-radius: 10px;

}

.feature_div {
  text-align: start;
  margin-left: 50px;
}

.feature_div div {
  padding: 5px 15px;
}

.ul_element {
  position: relative;
  color: #f1f1f1;
  padding: 10px 15px;
  list-style: none;
}

.ul_element:hover{
  background-color: #483a15;
}

.ul_element_active {
  color: white !important;
  background-color: #483a15;
  padding: 10px 15px;
}

.linktitle {
  color: white !important;
}

/* sidebar three start */